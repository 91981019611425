<template>
  <div id="chr">
    <Title title="来顶圣诞帽吧"></Title>
    <!-- 照片区 -->
    <div class="img-top">
      <div class="img-border">
        <div class="made-img">
          <!-- 大图 -->
          <img class="img" :src="imgUrl" @click="showBorder=false"/>
          <!-- 素材区 -->
          <div class="hat-con" v-show="showHat" @touchstart="showBorder=true">
            <div class="hat-border" v-show="showBorder">
              <div class="del" @touchmove="moveBar($event)">
                <p class="bar">||</p>
              </div>
            </div>
            <img class="imghat" :src="hatUrl" @touchstart="moveStart($event)" @touchmove="moveHat($event)"
                 @touchend="moveEnd()"/>
          </div>
        </div>
      </div>
      <div class="button-group">
        <!-- accept属性限制上传文件类型 -->
        <!--        <input class="upload" type="file" @change="fileChange" accept="image/jpeg,image/png,image/jpg">-->
        <input class="upload" style="display: none" ref="avatar" type="file" @change="fileChange"
               accept="image/jpeg,image/png,image/jpg">
        <md-button type="primary" size="small" inline @click="$refs.avatar.click()">更换图片</md-button>
        <md-button type="warning" size="small" inline @click="drawCanvas">保存</md-button>
      </div>
    </div>
    <!-- 选择帽子 -->
    <div class="hat-box">
      <div class="hat-select">
        <div class="hat-border" v-for="hat in hatLists" @click="selectHat(hat)">
          <img :src="hat">
        </div>
      </div>
    </div>

    <!-- 生成图片 -->
    <div class="download" v-show="isProduce" @click="isProduce=false">
      <img :src="downLoadImgUrl" alt="">
      <p>长按图片保存</p>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";

export default {
  name: "Christmas",
  mounted() {
    this.$API.user.getUserInfo().then(res=>{
      this.getBase64(res.data.headimgurl)
    })

  },
  data() {
    let hatLists = []
    for (let i = 1; i <=13; i++) {
      hatLists.push(`/assets/christmas/hat${i}.png`)
    }
    return {
      imgUrl: "",
      hatLists: hatLists,
      sPosition: {
        x: 0,
        y: 0
      },
      dX: 0,
      dY: 0,
      moveX: 0,
      moveY: 0,
      scale: 1, //放大的尺寸
      showHat: false,
      showBorder: false,
      hatUrl: "",
      downLoadImgUrl: "",
      isProduce: false,
      centerX: 0,
      centerY: 0,
      rotate: 0,
      isPicture: false
    };
  },
  methods: {
     getBase64(imgUrl) {
       let self = this
  window.URL = window.URL || window.webkitURL;
  var xhr = new XMLHttpRequest();
  xhr.open("get", imgUrl, true);
  // 至关重要
  xhr.responseType = "blob";
  xhr.onload = function () {
    if (this.status == 200) {
      //得到一个blob对象
      var blob = this.response;
      console.log("blob", blob)
      // 至关重要
      let oFileReader = new FileReader();
      oFileReader.onloadend = function (e) {
        let base64 = e.target.result;
        console.log("方式一》》》》》》》》》", base64)
        self.imgUrl = base64;
        self.isPicture = true;
      };
      oFileReader.readAsDataURL(blob);
      //====为了在页面显示图片，可以删除====

    }
  }
  xhr.send();
},

    // 上传图片显示
    fileChange(event) {
      event.preventDefault();
      this.isPicture = false;
      let self = this;
      let reader = new FileReader();
      let img = event.target.files[0];
      reader.readAsDataURL(img);
      reader.onloadend = function () {
        self.imgUrl = reader.result;
        self.isPicture = true;
      };
    },
    // 保存图片生成canvas
    drawCanvas() {
      let self = this;
      if (!self.isPicture) {
        return;
      }
      this.showBorder = false;
      setTimeout(function () {
        html2canvas(document.querySelector(".made-img")).then(canvas => {
          self.downLoadImgUrl = canvas.toDataURL("image/jpeg");
          self.isProduce = true;
        });
      }, 0);
    },
    moveStart(e) {
      e.preventDefault();
      this.sPosition = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      };
    },
    moveHat(e) {
      e.preventDefault();
      let moveDom = document.querySelector(".hat-con");
      this.moveX = e.touches[0].clientX - this.sPosition.x;
      this.moveY = e.touches[0].clientY - this.sPosition.y;

      moveDom.style.transform = `translateX(${this.dX +
      this.moveX}px) translateY(${this.dY + this.moveY}px) scale(${
          this.scale
      }) rotate(${this.rotate}deg)`;
      moveDom.style.webkitTransform = `translateX(${this.dX +
      this.moveX}px) translateY(${this.dY + this.moveY}px) scale(${
          this.scale
      }) rotate(${this.rotate}deg)`;
    },
    moveEnd() {
      this.dX += this.moveX;
      this.dY += this.moveY;
      this.centerX += this.moveX;
      this.centerY += this.moveY;
    },
    moveBar(e) {
      let moveDom = document.querySelector(".hat-con");
      let delDom = document.querySelector(".del");

      let rX = e.touches[0].clientX - this.centerX;
      let rY = e.touches[0].clientY - this.centerY;
      if (rX >= 0) {
        this.rotate = (Math.atan(rY / rX) - Math.PI * 0.25) / Math.PI * 180;
      } else {
        this.rotate = (Math.atan(rY / rX) + Math.PI * 0.75) / Math.PI * 180;
      }

      this.scale =
          Math.sqrt(
              Math.pow(e.touches[0].clientX - this.centerX, 2) +
              Math.pow(e.touches[0].clientY - this.centerY, 2)
          ) /
          (Math.sqrt(2) * 50);

      if (this.scale < 0.5) {
        this.scale = 0.5;
      }
      if (this.scale > 2) {
        this.scale = 2;
      }

      moveDom.style.transform = `translateX(${this.dX}px) translateY(${
          this.dY
      }px) scale(${this.scale}) rotate(${this.rotate}deg)`;
      moveDom.style.webkitTransform = `translateX(${this.dX}px) translateY(${
          this.dY
      }px) scale(${this.scale}) rotate(${this.rotate}deg)`;
      // moveDom.style.transform = `rotate(${this.rotate}deg)`;
    },
    // 还原样式
    clearHat() {
      let moveDom = document.querySelector(".hat-con");
      moveDom.style.webkitTransform = "";
      moveDom.style.transform = "";
      this.dX = 0;
      this.dY = 0;
      this.moveX = 0;
      this.moveY = 0;
      this.scale = 1;
      this.rotate = 0;
    },
    selectHat(hat) {
      if (!this.isPicture) {
        return;
      }
      this.showHat = true;
      this.showBorder = true;
      this.hatUrl = hat;
      this.clearHat();
      let self = this;
      let moveDom = document.querySelector(".hat-con");
      let delDom = document.querySelector(".del");
      setTimeout(function () {
        self.centerY =
            (delDom.getBoundingClientRect().top +
                moveDom.getBoundingClientRect().top +
                10) /
            2;
        self.centerX =
            (delDom.getBoundingClientRect().left +
                moveDom.getBoundingClientRect().left +
                10) /
            2;
        // alert(delDom.getBoundingClientRect().x);
      }, 0);
    }
  }
}
</script>
<!--$red: #cd0000;-->
<style scoped lang="stylus">
#chr {
  .title {
    img {
      position: fixed;
      margin-top: -30px;
      z-index: -1;
      width: 100%;
      height: 100%;
    }
  }

  .img-top {
    width: 600px;
    margin: 0 auto;

    .img-border {
      display: block;
      width: 600px;
      height: 600px;
      border: 3px solid #ddd;
      background: #fff;
      border-radius: 5px;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;

      .img {
        max-width: 600px;
        min-height: 600px;
        overflow: hidden;
        clip: rect(0, 0, 600px, 600px);
      }

      .hat-con {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        width: 200px;
        height: 200px;
        margin: -50px 0 0 -50px;

        .hat-border {
          position: absolute;
          border: 1px dashed #ff0000;
          width: 200px;
          height: 200px;

          .del {
            position: absolute;
            z-index: 5;
            bottom: -25px;
            right: -25px;
            width: 50px;
            height: 50px;
            background: #ff0000;
            transform: scale(1) !important;

            .bar {
              background: #ff0000;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              line-height: 20px;
              margin: 0 auto;
              margin-top: 15px;
              color: #fff;
              text-align: center;
              font-size: 12px;
            }
          }
        }

        .imghat {
          position: absolute;
          width: 200px;
          height: 200px;
        }
      }

      canvas {
        width: 600px;
        height: 600px;
      }
    }

    .button-group {
      display flex
      justify-content space-between
      margin-top: 20px;
      font-size 20px

      .upload {
        position: relative;
        //width: 50px;
        margin-right: 15px;

        &::after {
          content: "上传图片";
          position: absolute;
          top: 0;
          left: 0;
          //width: 190px;
          //font-size 10px
          //height: 20px;
          display: block;
          cursor: pointer;
          background: #586f80;
          border: 2px solid #fff;
          color: #fff;
          text-align: center;
          //line-height: 40px;
          border-radius: 5px;
        }
      }

      /* a {
         display: inline-block;
         //width: 100px;
         //height: 40px;
         float: right;
         background: #586f80;
         border: 2px solid #fff;
         color: #fff;
         text-align: center;
         //border-radius: 5px;
         //line-height: 40px;
       }*/
    }
  }

  .hat-box {
    width: 100%;
    //height: 150px;
    overflow: hidden;
    //border-bottom: 1px solid #586f80;

    .hat-select {
      margin-top: 10px;
      width: auto;
      //height: 140px;
      // border-top: 2px solid #000;
      background: #fff;
      overflow-x: scroll;
      overflow-y: hidden;

      .hat-border {
        width: 200px;
        height: 200px;
        display: table-cell;

        img {
          border-radius: 5px;
          margin: 10px;
          padding: 5px;
          box-sizing: border-box;
          border: 2px solid #fff;
          background: #d8e1f1;
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  .download {
    height: 100%;
    width: 100%;
    background: rgba(1, 1, 1, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    text-align: center;

    img {
      width: 600px;
      height: 600px;
      border: 2px solid #fff;
      margin-top: 30px;
    }

    p {
      width: 6em;
      margin: 50px auto;
      color: #fff;
    }
  }
}
</style>

